<template>
    <v-container fluid>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12">
                    <v-btn icon
                           light
                           @click="$router.go(-1)">
                    </v-btn>
                    <b-col cols="6">
                        <dmx-title v-if="localeStringResourceId != 0" backlink :bc-items="bcItems">{{localeStringResource.language}}:{{ localeStringResource.resourceName }}</dmx-title>
                        <dmx-title v-else backlink :bc-items="bcItems">{{ $t('common_create') }}</dmx-title>
                    </b-col>
                    <!--<h1 v-if="localeStringResourceId != 0">{{languageName}}: {{localeStringResource.resourceName}}</h1>
                    <h1 v-else>{{languageName}}: {{ $t('common_create') }}</h1>-->     
                </v-col>
                <b-col cols="12" class="d-flex align-items-end justify-content-end pb-4">
                    <b-button v-if="localeStringResourceId != 0" variant="danger" v-b-modal.delete-localeStringResource-modal class="mr-1">
                        {{ $t('common_delete') }}
                    </b-button>
                    <b-button variant="success" @click="saveLocaleStringResource()">
                        {{ (localeStringResourceId != 0) ? $t('common_save') : $t('common_create') }}
                    </b-button>
                </b-col>
            </v-row>
        </v-container>

        <v-card style="background-color: #f3f2e8;" class="pa-5">
            <div class="dmx-card mt-3">

                <b-row no-gutters align-v="center">
                    <b-col lg="2" align="left" class="pr-2">
                        {{ $t('admin_dashboard_localization_resourcename') }}:
                    </b-col>
                    <b-col lg="10">
                        <b-form-input v-model="localeStringResource.resourceName"
                                      :disabled="localeStringResourceId != 0">
                        </b-form-input>
                    </b-col>
                </b-row>
                <b-row no-gutters align-v="center" class="mt-3">
                    <b-col lg="2" align="left" class="pr-2">
                        {{ $t('admin_dashboard_localization_resourcevalue') }}:
                    </b-col>
                    <b-col lg="10">
                        <b-form-input v-model="localeStringResource.resourceValue"></b-form-input>
                    </b-col>
                </b-row>
            </div>

            <b-modal size="md" id="delete-localeStringResource-modal" title="Delete localeStringeResource" @ok="deleteLocaleStringResource()">
                <p>
                    You are about to delete this localeStringResource (<b>{{ localeStringResource.resourceName }}</b>).<br />
                    <b>This operation cannot be undone!</b>
                </p>

                <template #modal-footer="{ hide, ok }">
                    <b-button variant="primary" @click="hide()">
                        {{ $t('common_cancel') }}
                    </b-button>
                    <b-button variant="danger" @click="ok()">
                        {{ $t('common_delete') }}
                    </b-button>
                </template>
            </b-modal>


            <div style="flex: 1 1 auto;"></div>
        </v-card>
        <!--<v-snackbar v-model="snackbar"
                    :timeout="snackbarTimeout">{{snackbarText}}</v-snackbar>-->
    </v-container>
</template>

<script>
    import localeStringResourceService from '@/services/localeStringResourceService';
    import router from '@/router';

    export default {
        name: 'localeStringResourceEdit',
        props: ["localeStringResourceId", "languageId", "languageName"],
        components: {
        },
        data: () => ({
            localeStringResource: {
                id: 0,
                resourceName: '',
                resourceValue: '',
                language: '',
                languageId: 0,
            },
            deleteDialog: false,
            snackbar: false,
            snackbarText: '',
            snackbarTimeout: 3000
        }),
        methods: {
            deleteLocaleStringResource() {
                localeStringResourceService.deleteLocaleStringResource(this.localeStringResource.id)
                    .then(data => {
                        router.go(-1)
                    });
            },
            saveLocaleStringResource() {
                if (this.localeStringResource.id != 0) {
                    localeStringResourceService.updateLocaleStringResource(this.localeStringResource)
                        .then(data => {
                            if (data.success) {
                                this.snackbarText = data.message;
                                this.snackbar = true;
                                router.go(-1);
                            }
                        });
                } else {
                    localeStringResourceService.createLocaleStringResource(this.localeStringResource)
                        .then(data => {
                            if (data.success) {
                                this.snackbarText = data.message;
                                this.snackbar = true;
                                router.go(-1);
                            }
                        });
                }
            },
            fillView(data) {
                this.localeStringResource = data;
            }
        },
        computed: {
            bcItems() {
                return [
                    {
                        text: 'localization',
                        href: '/localeStringResources'
                    },
                    {
                        text: this.localeStringResource.resourceName,
                        href: '/localeStringResource/' + this.localeStringResource.id,
                        active: true
                    }
                ]
            }
        },
        mounted() {
            this.localeStringResource.languageId = parseInt(this.languageId);
            if (this.localeStringResourceId != 0) {
                localeStringResourceService.getLocaleStringResource(this.localeStringResourceId)
                    .then(data => {
                        if (data) {
                            this.fillView(data);
                            //console.log(data);
                        }
                    });
            }
        },
    }
</script>